<template>
  <nav id="navbar1" class="navbar navbar-expand-md navbar-light fixed-top">
    <div class="container">
      <a class="navbar-brand" href="#"><img src="/images/mydebitsrc-logo.svg" width="100%"></a>

      <input type="checkbox" id="overlay-input" />
      <label for="overlay-input" id="overlay-button" class="d-lg-none d-xl-none"><span></span></label>
      <div id="overlay">
        <ul>
          <li><router-link to="/home" class="nav-link">Home</router-link></li>
          <li><router-link to="/addresses" class="nav-link">Shipping addresses</router-link></li>
          <li><router-link to="/settings" class="nav-link" >Settings</router-link></li>
          <li><router-link to="/logout" class="nav-link" >Logout</router-link></li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  methods: {
    logout() {
      sessionStorage.clear()
      location.reload(true)
    },
  }
}
</script>