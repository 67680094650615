<template>
  <PopUp :show="alert.message!=null" :closeOnBackdrop="true" >
    <template>
      <div class="form-box">
        <!-- <div id="desktop-header-wrapper" class="container" v-if="alert.type == 'success'">
          <div class="row mb-2">
            <div class="col-12 no-padding">
              <h1 class="header-title text-center mt-3">
                <span>{{ alert.title || 'System Message'}}</span><br/>
              </h1>
            </div>
          </div>
        </div> -->
        <div class="form-wrapper text-center mt-3">
          <p class="col-11 mx-auto" v-html="alert.message"></p>
          <div class="submit-section col-8 mx-auto">
            <button class="form-control btn" :class="buttonType" @click="backBtn">OK</button>
          </div>
        </div>
      </div>
    </template>
  </PopUp>
</template>

<script>
import PopUp from './PopUp'

export default {
  components: { PopUp },
  data () {
    return {
      alert:null
    }
  },
  created() {
    this.alert = this.$store.state.alert;
  },
  computed: {
    buttonType: function() {
      if(this.alert.type == 'success') {
        return 'btn-primary'
      } else if(this.alert.type == 'danger') {
        return 'btn-danger'
      } else {
        return 'btn-info'
      }
    }
  },
  methods: {
    backBtn () {
      this.$store.dispatch('alert/clear');
    }
  }
}
</script>