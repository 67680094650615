const alert = {
  namespaced: true,
  state: {
    type: null,
    message: null,
    title: null
  },
  actions: {
    success ({ commit }, payload) {
      commit('success', payload)
    },
    error ({ commit }, payload) {
      commit('error', payload)
    },
    clear ({ commit }, message) {
      commit('clear', message)
    }
  },
  mutations: {
    success (state, payload) {
      state.type = 'success'
      state.message = payload.message
      state.title = payload.title
    },
    error (state, payload) {
      state.type = 'danger'
      state.message = payload.message
      state.title = payload.title
    },
    clear (state) {
      state.type = null
      state.message = null
      state.title = null
    }
  }
}

export default alert
