<template>
  <footer>
    <div class='container'>
      <div class='row'>
        <div class="col-12" v-if="showContact">
            <div style="width: fit-content; height: auto; padding: 12px 20px; border-radius: 10px; background-color:#EFEFEF; margin-bottom: 20px; vertical-align: middle;">
                <div class="footer-help" style="font-size: 0.8rem; color: #787E84; text-align: left;">For any enquiries, please contact us at <a href="mailto:customersupport@paynet.my">customersupport@paynet.my</a> or <a href="tel:1800186377">1800 18 6377</a></div>
            </div>
        </div>
        <div class='col-sm-6 col-12 footer-text-left text-left order-2 order-sm-1'>Copyright ©2021 PayNet. All Rights Reserved.</div>
        <div class='col-sm-6 col-12 footer-text-right text-right order-1 order-sm-2' :class="{ 'text-white': whiteFooter }">
          <a :href="tnc" target="_blank">TERMS & CONDITIONS</a>&nbsp;&nbsp;&#124;&nbsp;&nbsp;
          <a :href="privacy" target="_blank" >PRIVACY POLICY</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  props: {
    tnc: {
      type:String,
      default: ''
    },
    privacy: {
      type:String,
      default: ''
    },
    showContact: {
      type: Boolean,
      default: false
    },
    whiteFooter: {
      type: Boolean,
      default: false
    }
  }
}
</script>