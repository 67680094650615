import Vue from 'vue'
import VueRouter from 'vue-router'
import Container from '@/views/portal/PortalContainer.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Base',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Index" */ '@/views/landing/LandingIndex.vue')
  },
  {
    path: '/dcf',
    name: 'DCF',
    component: () => import(/* webpackChunkName: "Index" */ '@/views/dcf/DcfIndex.vue')
  },
  {
    path: '/dcf/3ds/result',
    name: '3DS result',
    props: { threeDsResult: true },
    component: () => import(/* webpackChunkName: "Card" */ '@/views/dcf/DcfIndex.vue')
  },
  {
    path: '/3ds/result',
    name: '3DS result',
    component: () => import(/* webpackChunkName: "Card" */ '@/views/ThreeDSResult.vue')
  },
  {
    path: '/sdk/checkout/communication',
    name: 'SDK gateway',
    component: () => import(/* webpackChunkName: "Card" */ '@/views/SdkCommunication.vue')
  },
  {
    path: '/clickToPay',
    name: 'Click To Pay',
    component: () => import(/* webpackChunkName: "Card" */ '@/views/checkout/ClickToPay.vue')
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import(/* webpackChunkName: "Card" */ '@/views/checkout/CheckoutIndex.vue')
  },
  {
    path: '/checkout/review',
    name: 'Review checkout',
    component: () => import(/* webpackChunkName: "Card" */ '@/views/checkout/CheckoutReview.vue')
  },
  {
    path: '/checkout/logout',
    name: 'Logout checkout',
    component: () => import(/* webpackChunkName: "Card" */ '@/views/checkout/CheckoutLogout.vue')
  },
  {
    path: '/checkout/3ds/result',
    name: '3DS result',
    props: { threeDsResult: true },
    component: () => import(/* webpackChunkName: "Card" */ '@/views/checkout/CheckoutIndex.vue')
  },
  {
    path: '/',
    redirect: '/home',
    component: Container,
    children: [
      {
        path: '/home',
        name: 'Home',
        component: () => import(/* webpackChunkName: "Home" */ '@/views/portal/HomeScreen.vue')
      },
      {
        path: '/card/enrol',
        name: 'My cards / Add another card',
        component: () => import(/* webpackChunkName: "Card" */ '@/views/cards/CardEnrol.vue')
      },
      {
        path: '/card/enrol/address',
        name: 'My cards / Add another card',
        component: () => import(/* webpackChunkName: "Card" */ '@/views/cards/CardEnrol.vue')
      },
      {
        path: '/card/enrol/secure',
        name: 'Securing Card',
        component: () => import(/* webpackChunkName: "Card" */ '@/views/cards/CardEnrol.vue')
      },
      {
        path: '/settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "Setting" */ '@/views/settings/SettingIndex.vue')
      },
      {
        path: '/setting/mobile',
        name: 'Settings / Mobile number',
        component: () => import(/* webpackChunkName: "Setting" */ '@/views/settings/SettingMobile.vue')
      },
      {
        path: '/setting/profile',
        name: 'Settings / Manage profile',
        component: () => import(/* webpackChunkName: "Setting" */ '@/views/settings/SettingProfile.vue')
      },
      {
        path: '/setting/devices',
        name: 'Settings / Trusted devices',
        component: () => import(/* webpackChunkName: "Setting" */ '@/views/settings/SettingDevices.vue')
      },
      {
        path: '/setting/devices/faster-checkout',
        name: 'Settings / Trusted devices',
        component: () => import(/* webpackChunkName: "Setting" */ '@/views/LearnMore.vue')
      },
      {
        path: '/addresses',
        name: 'Shipping addresses',
        component: () => import(/* webpackChunkName: "Transaction" */ '@/views/addresses/AddressIndex.vue')
      },
      {
        path: '/address/new',
        name: 'Add shipping address',
        component: () => import(/* webpackChunkName: "Transaction" */ '@/views/addresses/AddressCreate.vue')
      },
      {
        path: '/address/:id/edit',
        name: 'Update shipping address',
        component: () => import(/* webpackChunkName: "Transaction" */ '@/views/addresses/AddressUpdate.vue')
      },
      {
        path: '/logout',
        name: '',
        component: () => import(/* webpackChunkName: "Transaction" */ '@/views/SrcLogout.vue')
      },
    ]
  },
  {
    path: '/privacy',
    name: 'Privacy policy',
    component: () => import(/* webpackChunkName: "Errors" */ '@/views/PrivacyPolicy.vue')
  },
  {
    path: '/tnc',
    name: 'Terms & conditions',
    component: () => import(/* webpackChunkName: "Errors" */ '@/views/TermCondition.vue')
  },   
  {
    path: '/401',
    name: 'Unauthorize',
    component: () => import(/* webpackChunkName: "Errors" */ '@/views/errors/401.vue')
  },
  {
    path: '/403',
    name: 'Forbidden',
    component: () => import(/* webpackChunkName: "Errors" */ '@/views/errors/403.vue')
  },
  {
    path: '/500',
    name: 'InternalServerError',
    component: () => import(/* webpackChunkName: "Errors" */ '@/views/errors/500.vue')
  },
  {
    path: '*',
    name: 'catch-all',
    component: () => import(/* webpackChunkName: "Errors" */ '@/views/errors/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
})


router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = /(clickToPay|dcf|checkout|login|logout|3ds|tnc|privacy|\/401|\/403|\/404|\/500)/
  const authRequired = !publicPages.test(to.path)

  // const loggedIn = sessionStorage.getItem('user')
  const loggedIn = sessionStorage.getItem('token')

  if (authRequired && !loggedIn) {
    next({
      path: "/login"
    })
  }
  // let user =  JSON.parse(loggedIn);
  // if(to.meta != null && to.meta.permission != null && !user.permissions.includes(to.meta.permission)){
  //   next('/403')
  // }

  next()
})

export default router
