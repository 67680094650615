<template>
  <div id="auto-logout">&nbsp;</div>
</template>
<script>
export default {
  data () {
    return { 
      userActivityThrottlerTimeout: null,
      userActivityTimeout: null
    }
  },
  props: {
    timeout: {
      type:Number,
      default: 900
    },
  },
  beforeMount() {
    this.activateActivityTracker();
  },
  beforeDestroy() {
    this.deactivateActivityTracker();
    clearTimeout(this.userActivityTimeout);
    clearTimeout(this.userActivityThrottlerTimeout);
  },
  methods: {
    activateActivityTracker() {
      window.addEventListener("mousemove", this.userActivityThrottler)
      window.addEventListener("scroll", this.userActivityThrottler)
      window.addEventListener("keydown", this.userActivityThrottler)
      window.addEventListener("resize", this.userActivityThrottler)
    },
    deactivateActivityTracker() {
      window.removeEventListener("mousemove", this.userActivityThrottler)
      window.removeEventListener("scroll", this.userActivityThrottler)
      window.removeEventListener("keydown", this.userActivityThrottler)
      window.removeEventListener("resize", this.userActivityThrottler)
    },
    resetUserActivityTimeout() {
      clearTimeout(this.userActivityTimeout)

      this.userActivityTimeout = setTimeout(() => {
        this.userActivityThrottler()
        this.inactiveUserAction()
      }, this.timeout*1000)
    },
    userActivityThrottler() {
      if (!this.userActivityThrottlerTimeout) {
        this.userActivityThrottlerTimeout = setTimeout(() => {
          this.resetUserActivityTimeout()
          clearTimeout(this.userActivityThrottlerTimeout)
          this.userActivityThrottlerTimeout = null
        }, 5000)
      }
    },
    inactiveUserAction() {
      this.$emit('inactive')
    },
  }
}
</script>