<template>
  <div class='d-none d-md-block d-lg-block col-lg-3 col-md-3 responsive-view'>
    <div class='desktop-menu'>
      <ul class='nav navbar-nav ml-auto'>
        <li class='nav-item'>
          <router-link to="/home" class="nav-link" v-bind:class="{'active': path == '/home'}" >Home</router-link>
        </li>
        <li class='nav-item'>
          <router-link to="/addresses" class="nav-link" v-bind:class="{'active': path == '/addresses'}" >Shipping addresses</router-link>
        </li>
        <li class='nav-item'>
          <router-link to="/settings" class="nav-link" v-bind:class="{'active': path == '/settings'}" >Settings</router-link>
        </li>
        <li class='nav-item'>
          <router-link to="/logout" class="nav-link" v-bind:class="{'active': path == '/logout'}" >Logout</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>

export default {
  computed: {
    path() {
      return this.$route.path
    },
  }
}
</script>