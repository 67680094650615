<template>
  <div>
    <SrcHeader />
    <div class="container">
      <div class="row">
        <SrcSideMenu />
        <div class="col-12 col-lg-6 col-md-9 responsive-view">
          <div class="row">
            <router-view></router-view>
          </div>
        </div>
      </div>
    </div>
    <Notification />
    <Footer 
      v-if="tnc || privacy"
      :tnc="tnc" 
      :privacy="privacy"
      :showContact="true"
      />
    <AutoLogout 
      v-if="idleTimeout > 0"
      :timeout="idleTimeout" 
      @inactive="inactiveUser" 
      />
  </div>
</template>

<script>
import SrcHeader from '@/components/SrcHeader'
import SrcSideMenu from '@/components/SrcSideMenu'
import Notification from '@/components/SrcNotification'
import Footer from '@/components/SrcFooter'
import AutoLogout from '@/components/AutoLogout'

export default {
  components: {
    SrcHeader,
    SrcSideMenu,
    Notification,
    Footer,
    AutoLogout
  },
  data() {
    return {
      idleTimeout: 0,
      tnc: '',
      privacy: ''
    };
  },
  created () {
    this.idleTimeout = parseInt(sessionStorage.idleTimeout)
    this.tnc = sessionStorage.tnc
    this.privacy = sessionStorage.privacy
  },
  methods: {
    inactiveUser () {
      this.$router.push('/logout')
    } 
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
